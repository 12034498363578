
import { onMounted, ref, watch } from "vue";
import usePurchaseInvoice from "@/modules/purchaseInvoices/composables/usePurchaseInvoice";
import usePanel from "@/composables/usePanel";
import useSelect2 from "@/composables/useSelect2";

const { purchaseInvoiceForm } = usePurchaseInvoice();

export default {
    props: ["catalogs", "mode"],
    setup() {
        const { showPanel, togglePanel } = usePanel();
        const { select2center, select2provider, select2account, select2location } = useSelect2(`?isLeaf=1`);

        const providerId = ref("");
        const paymentTerm = ref("");
        const code = ref("");
        const creationDate = ref("");
        const accountingCenterId = ref("");
        const accountingAccountPayableId = ref("");
        const reference = ref("");
        const locationId = ref("");
        const status = ref("");

        const datepickerConfig = {
            singleDatePicker: true,
            showDropdowns: true,
            width: "100%",
            locale: {
                format: "DD/MM/YYYY",
            },
            maxDate: new Date(),
        };

        onMounted(() => {
            providerId.value = purchaseInvoiceForm.value.providerId;
            paymentTerm.value = purchaseInvoiceForm.value.paymentTerm;
            code.value = purchaseInvoiceForm.value.code;
            creationDate.value = purchaseInvoiceForm.value.creationDate;
            accountingCenterId.value = purchaseInvoiceForm.value.accountingCenterId;
            accountingAccountPayableId.value = purchaseInvoiceForm.value.accountingAccountPayableId;
            reference.value = purchaseInvoiceForm.value.reference;
            locationId.value = purchaseInvoiceForm.value.locationId;
            status.value = purchaseInvoiceForm.value.status;
        });

        watch(providerId, (val) => (purchaseInvoiceForm.value.providerId = val));
        watch(paymentTerm, (val) => (purchaseInvoiceForm.value.paymentTerm = val));
        watch(code, (val) => (purchaseInvoiceForm.value.code = val));
        watch(creationDate, (val) => (purchaseInvoiceForm.value.creationDate = val));
        watch(accountingCenterId, (val) => (purchaseInvoiceForm.value.accountingCenterId = val));
        watch(accountingAccountPayableId, (val) => (purchaseInvoiceForm.value.accountingAccountPayableId = val));
        watch(reference, (val) => (purchaseInvoiceForm.value.reference = val));
        watch(locationId, (val) => (purchaseInvoiceForm.value.locationId = val));
        watch(status, (val) => (purchaseInvoiceForm.value.status = val));

        return {
            showPanel,
            togglePanel,
            providerId,
            paymentTerm,
            code,
            creationDate,
            accountingCenterId,
            accountingAccountPayableId,
            reference,
            locationId,
            status,
            select2provider,
            select2center,
            select2account,
            select2location,
            datepickerConfig,
            purchaseInvoiceForm,
        };
    },
};
