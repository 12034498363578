interface Item {
    quantity: number,
    unitPrice: number,
    discountPercentage: number,
    taxPercentage: number
}

declare const window: any

const useCalculation = () => {

    const subtotal = (item: Item | undefined) => {
        if (!item) {
            return 0;
        }

        const value = item.quantity * item.unitPrice;

        return roundNumber(value, 2) * 1;
    };

    const discount = (item: Item | undefined) => {
        if (!item) {
            return 0;
        }

        return (subtotal(item) * item.discountPercentage) / 100;
    };

    const tax = (item: Item | undefined) => {
        if (!item) {
            return 0;
        }

        let taxValue = subtotal(item) - discount(item)
        taxValue = phpround(taxValue, 2, 'PHP_ROUND_HALF_EVEN');

        return (taxValue * item.taxPercentage) / 100
    };

    const subtotalGlobal = (items: Item[]) => {
        let subtotalValue = items.reduce(function (acc, item) {
            return acc + (subtotal(item))
        }, 0);

        subtotalValue = phpround(subtotalValue, 2, 'ROUND_HALF_EVEN');

        return subtotalValue;
    };

    const discountGlobal = (items: Item[]) => {
        let discountValue = 0;

        items.forEach((item) => {
            const value = discount(item) || 0;
            discountValue += parseFloat(roundNumber(value, 4))
        });

        discountValue = Math.floor(discountValue * 1000) / 1000;
        discountValue = phpround(discountValue, 2, 'PHP_ROUND_HALF_DOWN');

        return discountValue;
    };



    const taxGlobal = (items: Item[]) => {

        const groups = window._.groupBy(items, 'taxPercentage');
        let taxValue = 0;

        window._.forEach(groups, (subgroups: any) => {
            const value = subgroups.reduce((acc: any, item: any) => {
                return acc + tax(item)
            }, 0);
            taxValue += parseFloat(roundNumber(value, 2))
        });

        taxValue = Math.floor(taxValue * 1000) / 1000;
        taxValue = phpround(taxValue, 2, 'ROUND_HALF_EVEN');

        return taxValue;
    };

    const totalGlobal = (items: Item[]) => {
        return subtotalGlobal(items) - discountGlobal(items) + taxGlobal(items);
    };

    const checkPrecision = (val: number, base: number) => {
        val = Math.round(Math.abs(val));
        return isNaN(val) ? base : val;
    };

    const roundNumber = (value: number, precision: number): any => {
        precision = checkPrecision(precision, 2);
        const power = Math.pow(10, precision);

        // Multiply up by precision, round accurately, then divide and use native toFixed()
        return (Math.round((value + 1e-8) * power) / power).toFixed(precision);
    };

    const phpround = (value: number, precision: number, mode: string) => {
        // making sure precision is integer
        precision |= 0
        const m = Math.pow(10, precision)
        value *= m
        // sign of the number

        const sgn = (value > 0 as any) | -(value < 0)
        const isHalf = value % 1 === 0.5 * sgn
        const f: any = Math.floor(value)

        if (isHalf) {
            switch (mode) {
                case 'PHP_ROUND_HALF_DOWN':
                    // rounds .5 toward zero
                    value = f + (sgn < 0 as boolean)
                    break
                case 'PHP_ROUND_HALF_EVEN':
                    // rouds .5 towards the next even integer
                    value = f + (f % 2 * sgn)
                    break
                case 'PHP_ROUND_HALF_ODD':
                    // rounds .5 towards the next odd integer
                    value = f + !(f % 2)
                    break
                default:
                    // rounds .5 away from zero
                    value = f + (sgn > 0)
            }
        }

        return (isHalf ? value : Math.round(value)) / m
    };

    return {
        subtotal,
        subtotalGlobal,
        discount,
        discountGlobal,
        tax,
        taxGlobal,
        totalGlobal
    }
}

export default useCalculation;
